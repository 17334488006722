<template>
  <div class="resume-details">
    <!-- 书签 -->
    <div class="title">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/aiHome' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/aiCompetition/list' }">评审列表</el-breadcrumb-item>
        <el-breadcrumb-item>详情</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="contest-main">
      <el-row :gutter="24">
        <el-col :span="16">
          <div class="col-left" v-if="isPDF">
            <div v-for="item in numPagesPdf" :key="'pdf-' + item">
              <pdf v-if="resumePdf" :page="item" :src="resumePdf"></pdf>
            </div>
            <div v-for="path in numPagesPath" :key="'path-' + path">
              <pdf v-if="resumePdfData" :page="path" :src="resumePdfData"></pdf>
            </div>
          </div>
          <div class="flow-l" v-if="isPath">
            <div class="col-center">
              <div class="main-top">
                <div class="personal-data">
                  <div class="data-name">{{ main_data.personal_details.name }}</div>
                  <div class="data-position">求职意向：{{ main_data.personal_details.position }}</div>
                  <div class="grade-location">
                    <span class="data">学历：{{ main_data.personal_details.grade }}</span>
                    <span>地址：{{ main_data.personal_details.location }}</span>
                  </div>
                  <div class="phone-email">
                    <span class="data">电话：{{ main_data.personal_details.phone }}</span>
                    <span>邮箱：{{ main_data.personal_details.email }}</span>
                  </div>
                </div>
                <div class="personal-img">
                  <el-image class="img" :src="main_data.personal_details.img" fit="cover"></el-image>
                </div>
              </div>
              <div class="main-bottom">
                <div class="main-data">
                  <div class="education-img">
                    <span class="title">教育背景</span>
                  </div>
                  <div class="education-span" v-for="item in main_data.education_data" :key="item.id">
                    <span>{{ item.college }}</span>
                    <span>{{ item.start_time }} - {{ item.end_time }}</span>
                    <span>{{ item.specialty }}</span>
                    <span>{{ item.education }}</span>
                    <span>{{ item.institute }}</span>
                  </div>
                </div>
                <div class="main-data">
                  <div class="education-img">
                    <span class="title">工作经历</span>
                  </div>
                  <div class="item-data" v-for="item in main_data.work_data" :key="item.id">
                    <div class="data-title">
                      <span class="title-left">{{ item.work_title }}</span>
                      <span>{{ item.start_time }} - {{ item.end_time }}</span>
                    </div>
                    <div class="data-content">
                      {{ item.work_experience }}
                    </div>
                  </div>
                </div>
                <div class="main-data">
                  <div class="education-img">
                    <span class="title">项目经验</span>
                  </div>
                  <div class="item-data" v-for="item in main_data.project_data" :key="item.id">
                    <div class="data-title">
                      <span class="title-left">{{ item.project_title }}</span>
                      <span>{{ item.start_time }} - {{ item.end_time }}</span>
                    </div>
                    <div class="data-content">
                      {{ item.project_experience }}
                    </div>
                  </div>
                </div>
                <div class="main-data">
                  <div class="education-img">
                    <span class="title">技能优势</span>
                  </div>
                  <div class="item-data">
                    <div class="data-content">
                      {{ main_data.skill_data }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="col-right">
            <div class="right-top">
              <div class="top-progress">
                <el-progress :show-text="false" :stroke-width="14" :width="168" type="circle" :percentage="progressCount"></el-progress>
                <div class="progress-data">
                  <span class="progress-count">{{ progressCount }}</span>
                </div>
              </div>
              <div class="completeness">
                <span v-if="hearten">优胜奖</span>
                <span v-else>第{{ ranking }}名</span>
              </div>
              <el-checkbox v-model="hearten" @change="changeHearten">优胜奖</el-checkbox>
            </div>
            <div class="right-bottom">
              <el-form :model="score_message" :rules="rules" ref="rulForm">
                <div class="message">
                  <el-form-item label="个人信息全面度（20分）" prop="personal_information">
                    <el-input @change="updateScoreMessage" type="number" v-model="score_message.personal_information" size="small" />
                  </el-form-item>
                  <el-form-item label="评价">
                    <el-input @change="updateScoreMessage" type="textarea" v-model="score_message.personal_information_textarea" size="small" />
                  </el-form-item>
                </div>
                <div class="message">
                  <el-form-item label="实习实践参与度（30分）" prop="practice">
                    <el-input @change="updateScoreMessage" type="number" v-model="score_message.practice" size="small" />
                  </el-form-item>
                  <el-form-item label="评价">
                    <el-input @change="updateScoreMessage" type="textarea" v-model="score_message.practice_textarea" size="small" />
                  </el-form-item>
                </div>
                <div class="message">
                  <el-form-item label="获奖的价值度（30分）" prop="award">
                    <el-input @change="updateScoreMessage" type="number" v-model="score_message.award" size="small" />
                  </el-form-item>
                  <el-form-item label="评价">
                    <el-input @change="updateScoreMessage" type="textarea" v-model="score_message.award_textarea" size="small" />
                  </el-form-item>
                </div>
                <div class="message">
                  <el-form-item label="特色特长（20分）" prop="strong_point">
                    <el-input @change="updateScoreMessage" type="number" v-model="score_message.strong_point" size="small" />
                  </el-form-item>
                  <el-form-item label="评价">
                    <el-input @change="updateScoreMessage" type="textarea" v-model="score_message.strong_point_textarea" size="small" />
                  </el-form-item>
                </div>
              </el-form>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <el-image class="logo-image" :src="require(`../../../../assets/image/logomini.png`)"></el-image>
  </div>
</template>

<script>
import pdf from 'vue-pdf'
import { getResume, getRanking, updateHearten, updateScore } from '../../../../request/api'

export default {
  components: {
    pdf
  },
  data() {
    return {
      resume_id: '',
      main_data: {
        personal_details: {
          name: '',
          position: '',
          grade: '',
          location: '',
          phone: '',
          email: '',
          img: 'https://bmbshopping.oss-cn-hangzhou.aliyuncs.com/shopgoods/0.1372555556030275f52fcef3161f505f12aa10574f3ce35.png',
          sex: ''
        },
        education_data: [],
        work_data: [],
        project_data: [],
        skill_data: '',
      },
      hearten: false,
      isPDF: false,
      resumePdf: null,
      numPagesPdf: null,
      resumePdfData: null,
      numPagesPath: null,
      isPath: false,

      progressCount: null,
      ranking: null,
      score_message: {
        personal_information: 0,
        personal_information_textarea: '',
        practice: 0,
        practice_textarea: '',
        award: 0,
        award_textarea: '',
        strong_point: 0,
        strong_point_textarea: ''
      },
      rules: {
        personal_information: [
          {
            validator: (rule, value, callback) => {
              if (value > 20 || value < 0) {
                callback(new Error('打分在0-20之间'))
              } else {
                callback()
              }
            },
            trigger: ['blur', 'change']
          }
        ],
        practice: [
          {
            validator: (rule, value, callback) => {
              if (value > 30 || value < 0) {
                callback(new Error('打分在0-30之间'))
              } else {
                callback()
              }
            },
            trigger: ['blur', 'change']
          }
        ],
        award: [
          {
            validator: (rule, value, callback) => {
              if (value > 30 || value < 0) {
                callback(new Error('打分在0-30之间'))
              } else {
                callback()
              }
            },
            trigger: ['blur', 'change']
          }
        ],
        strong_point: [
          {
            validator: (rule, value, callback) => {
              if (value > 20 || value < 0) {
                callback(new Error('打分在0-20之间'))
              } else {
                callback()
              }
            },
            trigger: ['blur', 'change']
          }
        ],
      }
    }
  },
  methods: {
    updateScoreMessage() {
      this.$refs.rulForm.validate(valid => {
        if (valid) {
          this.updateScoreApi({ ...this.score_message, resume_id: this.resume_id })
          this.init()
          this.getRank()
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    async updateScoreApi(postData) {
      try {
        const res = await updateScore(postData)
        if (res.status !== 200 || res.data.code !== '1000') {
          this.$message.error(res.data.msg)
          return
        }

        this.$message.success('修改成功')
      } catch(error) {
        this.$message.error(error);
      }
    },
    async getRank() {
      try {
        const res = await getRanking(this.resume_id)
        if (res.status !== 200 || res.data.code !== '1000') {
          this.$message.error(res.data.msg)
          return
        }

        this.ranking = res.data.data
      } catch(error) {
        this.$message.error(error);
      }
    },
    async changeHearten(value) {
      try {
        const res = await updateHearten({resume_id: this.resume_id, hearten: value })
        if (res.status !== 200 || res.data.code !== '1000') {
          this.$message.error(res.data.msg)
          return
        }

        this.$message.success('修改成功')
      } catch(error) {
        this.$message.error(error)
      }
    },
    async init() {
      try {
        const res = await getResume(this.resume_id)
        if (res.status !== 200 || res.data.code !== '1000') {
          this.$message.error(res.data.msg)
          return
        }
        const resumeMy = res.data.data

        this.hearten = resumeMy.hearten === 0 ? false : true

        if (resumeMy.score === null || resumeMy.score === '') {
          this.progressCount = 0
        } else {
          let numbers = resumeMy.score.split(',')
          this.progressCount = numbers.reduce((accumulator, currentValue) => {
            return accumulator + parseInt(currentValue, 10)
          }, 0)

          this.score_message.personal_information = numbers[0]
          this.score_message.practice = numbers[1]
          this.score_message.award = numbers[2]
          this.score_message.strong_point = numbers[3]
        }

        if (resumeMy.score_message !== null && resumeMy.score_message !== '') {
          let message = resumeMy.score_message.split(',')

          this.score_message.personal_information_textarea = message[0]
          this.score_message.practice_textarea = message[1]
          this.score_message.award_textarea = message[2]
          this.score_message.strong_point_textarea = message[3]
        }

        if (resumeMy.radio === 1) {
          this.isPath = true
          this.main_data = JSON.parse(resumeMy.resume)
        } else {
          this.isPDF = true

          const loadingTaskPdf = pdf.createLoadingTask(resumeMy.pdfImg)
          loadingTaskPdf.promise.then(pdf => {
            this.numPagesPdf = pdf.numPages

            this.resumePdf = resumeMy.pdfImg
          }).catch(error => {
            this.$message.error(error)
          })

          if (resumeMy.pathImg !== null) {
            const loadingTaskPath = pdf.createLoadingTask(resumeMy.pathImg)
            loadingTaskPath.promise.then(pdf => {
              this.numPagesPath = pdf.numPages

              this.resumePdfData = resumeMy.pathImg
            }).catch(error => {
              this.$message.error(error)
            })
          }

        }
      } catch(error) {
        this.$message.error(error);
      }
    }
  },
  created() {
    this.resume_id = this.$route.query.shop_user_ai_resume_id
  },
  mounted() {
    this.init()
    this.getRank()
  }
}
</script>

<style lang="less" scoped>
.resume-details {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  .title {
    padding: 10px;
    background-color: #fff;
    box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.16);
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    margin-bottom: 10px;
  }
  .contest-main {
    padding: 4px;
    z-index: 2;
    height: 1420px;
    .el-row {
      height: 100%;
      .el-col {
        height: 100%;
        .flow-l {
          height: 100%;
          overflow-y: auto;
          background-color: #fff;
          .col-center {
            height: 100%;
            display: flex;
            flex-direction: column;
            .main-top {
              min-height: 28%;
              display: flex;
              .personal-data {
                width: 70%;
                display: flex;
                flex-direction: column;
                padding: 30px 50px;
                box-sizing: border-box;
                .data-name {
                  font-size: 22px;
                  margin-bottom: 14px;
                  font-weight: bold;
                }
                .data-position {
                  margin-bottom: 10px;
                }
                .grade-location {
                  margin-bottom: 10px;
                  display: flex;
                  font-size: 14px;
                }
                .data {
                  width: 40%;
                }
                .phone-email {
                  display: flex;
                  font-size: 14px;
                }
              }
              .personal-img {
                flex: 1;
                display: flex;
                align-items: center;
                .img {
                  width: 140px;
                  height: 140px;
                  border-radius: 50%;
                }
              }
            }
            .main-bottom {
              flex: 1;
              padding: 28px;
              box-sizing: border-box;
              .main-data {
                display: flex;
                flex-direction: column;
                margin-bottom: 12px;
                .education-img {
                  height: 60px;
                  font-size: 20px;
                  display: flex;
                  align-items: center;
                  .title {
                    width: 100px;
                    text-align: center;
                    border-left: 5px solid #2a6ee9;
                  }
                }
                .item-data {
                  display: flex;
                  flex-direction: column;
                  margin-bottom: 10px;
                  .data-title {
                    display: flex;
                    font-weight: bold;
                    margin-bottom: 4px;
                    .title-left {
                      margin-right: 10px;
                    }
                  }
                  .data-content {
                    white-space: pre-wrap;
                    padding: 4px;
                    font-size: 14px;
                  }
                }
                .education-span {
                  display: flex;
                  font-size: 14px;
                  justify-content: space-between;
                  font-weight: bold;
                  margin-bottom: 10px;
                }
              }
            }
          }
        }

        .col-left {
          height: 100%;
          background-color: #fff;
          overflow-y: auto;
        }
        .col-right {
          background-color: #fff;
          height: 100%;
          display: flex;
          flex-direction: column;
          .right-bottom {
            flex: 1;
            padding: 10px;
            .message {
              border: 1px solid #2a6ee9;
              padding: 20px;
              margin-bottom: 10px;
            }
          }
          .right-top {
            height: 330px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            .el-checkbox {
              font-weight: bold;
            }
            .completeness {
              background-color: #2a6ee9;
              color: #fff;
              font-size: 22px;
              font-weight: bold;
              width: 80%;
              height: 44px;
              line-height: 44px;
              text-align: center;
              border-radius: 24px;
              margin-bottom: 10px;
            }
            .top-progress {
              position: relative;
              margin-bottom: 20px;
              .progress-data {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                .progress-count {
                  color: #2a6ee9;
                  font-size: 58px;
                  font-weight: bold;
                }
              }
            }
          }
        }
      }
    }

  }
  .logo-image {
    pointer-events: none;
    position: absolute;
    width: 50%;
    bottom: 0px;
    right: 0px;
    opacity: 0.2;
    z-index: 1;
  }
}
</style>